import React from "react";
import { useSelector } from "react-redux";
import Layout from "../../layouts/zh";
import SEO from "components/seo";
import Promotion from "../../services/Promotion";
import "../../style/promotion.css";
import langData from "../../data/static-pages/pages/promotion/zh";
import PromotionDetail from "../../services/PromotionDetail";
import { IN_PROGRESS } from "state/constants/actions";

const PromotionPage = ({ ...props }) => {
  const slug = props["*"] ? props["*"] : null;

  const { uiState: promotionUiState } = useSelector((state) => state.promotion);
  const { uiState: promotionsUiState } = useSelector(
    (state) => state.promotions
  );
  const isUiLoading =
    promotionUiState === IN_PROGRESS || promotionsUiState === IN_PROGRESS;

  return (
    <Layout location={props.location} footer_margin={isUiLoading}>
      <SEO title="Promotion " />
      {!slug ? (
        <Promotion
          langData={langData}
          langKey={props.pageContext.langKey}
          detailUrl={""}
        />
      ) : (
        <PromotionDetail
          langData={langData}
          langKey={props.pageContext.langKey}
          slug={slug}
          homeUrl={"/zh"}
          promotionUrl={"/zh/promotion"}
        />
      )}
    </Layout>
  );
};

export default PromotionPage;
